<template>
  <v-container class="mt-6">
    <h2 class="mb-4">Facebook</h2>
    <v-row>
      <v-col cols="12">
        <ManagedPages :key="managedPagesList" />
        <v-btn
          class="mt-3"
          color="facebook"
          @click="FB_login"
          depressed
          large
          dark
          >{{ fbButtonMessage }}<v-icon right dark>mdi-facebook</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ManagedPages from "@/components/connections/facebook/ManagedPages.vue";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
//import axios from "axios";

export default {
  components: {
    ManagedPages,
  },

  data: function () {
    return {
      fetchinData: false,
      isFBReady: false,
      managedPagesList: 0,
      fbButtonMessage: "Mit Facebook verbinden",
    };
  },

  mounted: function () {
    let that = this;

    if (!this.scriptExists("https://connect.facebook.net/en_US/sdk.js")) {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.async = true;
        js.src =
          document.location.protocol + "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        //console.log("fbAsyncInit");

        window.FB.init({
          appId: "322096512114727",
          cookie: true,
          xfbml: true,
          version: "v8.0",
        });

        window.FB.AppEvents.logPageView();
        that.checkLoginState();
      };
    } else {
      //console.log('FB is already inited');
      // FB is already inited
      window.FB.AppEvents.logPageView();
      that.checkLoginState();
    }

    this.isFBReady = window.FB != undefined;
    window.addEventListener("fb-sdk-ready", this.onFBReady);
  },

  beforeDestroy: function () {
    window.removeEventListener("fb-sdk-ready", this.onFBReady);
  },

  methods: {
    scriptExists(url) {
      return document.querySelectorAll(`script[src="${url}"]`).length > 0;
    },

    forceRerender() {
      this.managedPagesList += 1;
    },

    onFBReady: function () {
      //console.log('FB ready');
      this.isFBReady = true;
      this.facebookInit();
    },

    facebookInit: function () {
      console.log("FACEBOOK INIT");
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          console.log("connected");
          this.fbButtonMessage = "App-Einstellungen ändern";
        } else {
          console.log("not connected");
          this.fbButtonMessage = "Mit Facebook verbinden";
        }
      });
    },

    checkLoginState: function () {
      // check the facebook loginstate of user
      // console.log('2. checkLoginState');

      let that = this;

      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          //console.log('we are connected');
          //console.log(response);
          that.fbButtonMessage = "App-Einstellungen ändern";

          /* window.FBRegister.userID = response.authResponse.userID;
          window.FBRegister.accessToken = response.authResponse.accessToken;
          window.FBRegister.data_access_expiration_time = response.authResponse.data_access_expiration_time;
          window.FBRegister.signedRequest = response.authResponse.signedRequest; */

          //window.FBRegister.checkAccountUniqueness(); // hier wird geprüft, ob der verbundene Account auch zu unserem Account passt
        } else {
          // noch nicht verbunden
          //console.log('we are not connected');
          that.fbButtonMessage = "Mit Facebook verbinden";
        }
      });
    },

    FB_storeData: async function (authResponse) {
      this.fetchinData = true;
      let response = await this.postRequest(
        "facebook/insertSources",
        null,
        authResponse
      ); // await data from mixin
      this.fetchinData = false;
      if (response.status == 200) {
        alert("Unsere App wurde erfolgreich autorisiert!");
        this.forceRerender();
      } else {
        alert("Ein Fehler ist aufgetreten!");
      }

      /* let globalThis = this;
      // facebook/insertSources
      try {
        let token = store.getters["auth/token"]; // get from store
        await axios.post("facebook/insertSources", authResponse, {
          headers: {
            "Content-Type": "application/json",
            Token: token,
          },
        });

        //console.log(response);
        globalThis.forceRerender();
        alert("Unsere App wurde erfolgreich autorisiert!");
      } catch (e) {
        alert("An error ... damn!");
      } */
    },

    FB_login: function () {
      let component = this; // keep 'this'

      window.FB.login(
        function (response) {
          if (response.authResponse) {
            component.FB_storeData(response.authResponse);
          } else {
            //user hit cancel button
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope:
            "pages_manage_ads,leads_retrieval,pages_manage_metadata,pages_read_engagement,pages_show_list", // ads_management
        }
      );
    },
  },
  mixins: [manageApi, helpers],
};
</script>
