<template>
  <DataTable
    @tableAction="emittedAction"
    :items="items"
    :headers="headers"
    :itemActions="{}"
    :preloader="preloader"
  />
</template>

<script>
import manageApi from "@/mixins/manageApi";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  created: function () {
    this.getData(); // call it immediatly
  },

  data() {
    return {
      edit: false,
      trash: false,
      copy: false,
      search: "",
      headers: [],
      items: [],
      preloader: true,
      attrs: {
        boilerplate: true,
      },
    };
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest("facebook/accounts"); // await data from mixin
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "externalId" },
          { text: "Name", value: "name" },
          { text: "Tasks", value: "tasks" },
          { text: "Gültigkeit", value: "isValid" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data.accounts;
        (this.edit = false),
          (this.trash = false),
          (this.copy = false),
          (this.search = "");
        this.preloader = false;
      } else {
        alert("ERROR");
      }
    },

    emittedAction(value) {
      console.log("emittedAction:");
      console.log(value);

      if (value.action === "editItem") {
        this.$router.push({
          name: "csm.company-manager.:id",
          params: { id: value.itemId },
        });
      }
    },
  },
  components: {
    DataTable,
  },
  name: "ManagedPages",
  mixins: [manageApi],
};
</script>

<style scoped></style>
