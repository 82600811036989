<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.connections')"
    />

    <template>
      <v-tabs v-model="tab" align-with-title dark background-color="primary">
        <v-tabs-slider color="lime accent-2"></v-tabs-slider>
        <v-tab v-for="(item, index) in items" :key="index">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="tab" class="grey lighten-4">
      <v-tab-item v-for="(item, index) in items" :key="index">
        <v-card flat class="grey lighten-4">
          <component v-bind:is="item.component"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import store from "@/store";
import PageHeader from "@/components/ui/PageHeader";
import ConnectionsFacebook from "@/components/connections/ConnectionsFacebook";

export default {
  name: "connections",

  components: {
    PageHeader,
    ConnectionsFacebook,
  },

  data() {
    return {
      pageTitle: this.$t("connections"),
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [], // wrapper for fetched data
      tab: null,

      items: [
        {
          name: this.$t("facebook"),
          component: "ConnectionsFacebook",
        },
      ],

      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },

  props: [],

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
      }
    );
  },
};
</script>
